<script setup>
import dayjs from 'dayjs';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $services, $t, $toast, auth_store, router } = useCommonImports();

const PREFERENCES_DATA = {
  MENTIONS_ONLY: { title: 'Mentions', description: 'Notification related to assigned tasks, forms and @mentions', key: 'MENTIONS_ONLY' },
  ACTIVITY_UPDATES: { title: 'Activity updates', key: 'ACTIVITY_UPDATES', description: 'Activity related to all tasks and forms you\'ve been added to as users or watcher' },
  SHARED_ITEMS: { title: 'Shared items', key: 'SHARED_ITEMS', description: 'Someone shares a task, form, file, location or markup with you.' },
};

const form_values = ref({});
const form$ = ref(null);
const state = reactive({
  notification_data: null,
  is_loading: false,
  is_loading_desktop_notification: false,
});

function getTimeList() {
  const items = [];
  let current_date = dayjs().startOf('day');
  Array.from({ length: 48 }).fill().forEach(() => {
    items.push({
      label: current_date.format('h:mm a'),
      value: current_date.diff(dayjs().startOf('day'), 'second'),
    });
    current_date = current_date.add(30, 'minutes');
  });
  return items;
}

async function getData() {
  try {
    state.is_loading = true;
    await $services.notifications.getAll();
    const { data } = await $services.users.notifications({ id: auth_store.logged_in_user_details.user_id });
    state.notification_data = data.user;
  }
  catch (error) {
    logger.error(error);
  }
  finally {
    state.is_loading = false;
  }
}

function onNotificationsToggleClick() {
  state.is_loading_desktop_notification = true;
  if (form_values.value.desktop_notifications) {
    if (auth_store.is_one_signal_initialized) {
      OneSignal.push(() => {
        OneSignal.showNativePrompt();
      });
    }
    form_values.value.desktop_notifications = !form_values.value.desktop_notifications;
    state.is_loading_desktop_notification = false;
  }
  else {
    if (auth_store.is_one_signal_initialized) {
      OneSignal.push(() => {
        OneSignal.setSubscription(false);
      });
    }
    state.is_loading_desktop_notification = false;
  }
}

async function save() {
  try {
    const dnd_ends_on = ref('');
    if (form_values.value?.pause_notification) {
      dnd_ends_on.value = dayjs(new Date())
        .add(
          form_values.value.pause_notification.duration,
          form_values.value.pause_notification.type,
        )
        .toISOString();

      delete form_values.value.pause_notification;
    }
    else {
      dnd_ends_on.value = state.notification_data?.dnd_ends_on ? state.notification_data.dnd_ends_on : 0;
    }

    const form_values_copy = { ...form_values.value };
    delete form_values_copy.desktop_notifications;

    const payload = {
      ...form_values_copy,
      dnd_days: [],
      dnd_enabled: false,
      dnd_end_local_seconds: null,
      dnd_start_local_seconds: null,
      dnd_ends_on: null,
      email_summary_local_seconds: form_values.value?.send_daily_summary_email ? form_values.value?.email_summary_local_seconds : null,
    };

    const { data } = await $services.users.post({
      id: auth_store.logged_in_user_details.user_id,
      attribute: 'notifications',
      body: {
        user: payload,
      },
    });

    if (data.user) {
      $toast({
        text: 'Updated successfully',
        type: 'success',
      });
      await getData();
      form$.value.load({
        ...state.notification_data,
        send_daily_summary_email: !!data?.user?.email_summary_local_seconds,
      }, true);
      if (auth_store.is_one_signal_initialized) {
        OneSignal.push(() => {
          OneSignal.isPushNotificationsEnabled().then(async (desktop_notifications) => {
            form_values.value.desktop_notifications = desktop_notifications;
          });
        });
      }
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
    }
  }
  catch (error) {
    logger.error(error);
  }
}

onMounted(async () => {
  await getData();
  const form_data = {
    pause_notification: state.notification_data?.dnd_ends_on,
    ...state.notification_data,
    send_daily_summary_email: !!state.notification_data?.email_summary_local_seconds,
  };
  form$.value.load(form_data, true);

  if (auth_store.is_one_signal_initialized) {
    OneSignal.push(() => {
      OneSignal.isPushNotificationsEnabled().then(async (desktop_notifications) => {
        form_values.value.desktop_notifications = desktop_notifications;
      });
      OneSignal.on('subscriptionChange', (value) => {
        form_values.value.desktop_notifications = value;
      });
    });
  }
});
</script>

<template>
  <div>
    <HawkLoader v-if="state.is_loading" />
    <div v-else>
      <Vueform
        ref="form$"
        v-model="form_values"
        size="sm"
        sync
        :display-errors="false"
        :format-load="(data) => data"
        :endpoint="save"
      >
        <div class="col-span-12 text-sm">
          <div class="max-w-[700px]">
            <div class="text-lg font-semibold">
              {{ $t("Daily summary emails") }}
            </div>
            <div class="text-xs w-80 mb-5">
              {{ $t("Daily summaries include overview of upcoming and overdue tasks and forms") }}.
            </div>
            <ToggleElement
              name="send_daily_summary_email"
              :label="$t('Send daily summary emails')"
            />
          </div>
          <div class="max-w-[700px]">
            <SelectElement
              name="email_summary_local_seconds"
              :label="`${$t('Send summary emails everyday at')}:`"
              :search="false"
              :placeholder="$t('Time')"
              :items="getTimeList()"
              :native="false"
              class="mt-4"
              default="9:00 am"
              :can-clear="false"
              rules="required"
              :conditions="[['send_daily_summary_email', '==', true]]"
            />
          </div>

          <hr class="my-5">
          <div class="max-w-[700px]">
            <div class="text-lg font-semibold mb-5">
              {{ $t("Desktop notifications") }}
            </div>
            <ToggleElement
              name="desktop_notifications"
              :label="$t('Receive notifications directly on my device')"
              class="cursor-pointer"
              :disabled="state.is_loading_desktop_notification"
              @click="!state.is_loading_desktop_notification ? onNotificationsToggleClick() : null"
            >
              <template #default>
                <HawkLoader v-if="state.is_loading_desktop_notification" container_class="m-0" :height="5" :width="5" />
              </template>
            </ToggleElement>
          </div>
          <hr class="my-5">
          <div class="max-w-[700px]">
            <div class="text-lg font-semibold">
              {{ $t("Preferences") }}
            </div>
            <div class="text-xs w-80 mb-5">
              {{ $t("Send me notifications for") }}:
            </div>
            <div class="grid gap-y-10">
              <ObjectElement
                name="preferences"
                :initial="1"
                :columns="{
                  default: { container: 11, label: 12, wrapper: 12 },
                  sm: { container: 11, label: 12, wrapper: 12 },
                  md: { container: 11, label: 12, wrapper: 12 },
                }"
                :add-classes="{
                  ListElement: {
                    add: ['!py-2.25 hover:bg-gray-100 !align-middle'],
                    listItem: ['!mb-3'],
                  },
                }"
                :controls="{
                  add: false,
                  remove: false,
                  sort: false,
                }"
              >
                <div class="grid gap-10 col-span-12">
                  <ObjectElement name="MENTIONS_ONLY">
                    <template #label>
                      <div class="max-w-[280px]">
                        <div class="text-gray-700 font-medium">
                          {{ $t(PREFERENCES_DATA.MENTIONS_ONLY.title) }}
                        </div>
                        <div class="text-gray-500 font-normal">
                          {{ $t(PREFERENCES_DATA.MENTIONS_ONLY.description) }}
                        </div>
                      </div>
                    </template>
                    <ToggleElement name="email" class="mb-3">
                      {{ $t("Email") }}
                    </ToggleElement>
                    <ToggleElement name="browser" class="mb-3">
                      {{ $t("Browser") }}
                    </ToggleElement>
                    <ToggleElement name="mobile_app" class="mb-3">
                      {{ $t("Mobile App") }}
                    </ToggleElement>
                  </ObjectElement>

                  <ObjectElement name="ACTIVITY_UPDATES">
                    <template #label>
                      <div class="max-w-[280px]">
                        <div class="text-gray-700 font-medium">
                          {{ $t(PREFERENCES_DATA.ACTIVITY_UPDATES.title) }}
                        </div>
                        <div class="text-gray-500 font-normal">
                          {{ $t(PREFERENCES_DATA.ACTIVITY_UPDATES.description) }}
                        </div>
                      </div>
                    </template>
                    <ToggleElement name="email" class="mb-3">
                      {{ $t("Email") }}
                    </ToggleElement>
                    <ToggleElement name="browser" class="mb-3">
                      {{ $t("Browser") }}
                    </ToggleElement>
                    <ToggleElement name="mobile_app" class="mb-3">
                      {{ $t("Mobile App") }}
                    </ToggleElement>
                  </ObjectElement>

                  <ObjectElement name="SHARED_ITEMS">
                    <template #label>
                      <div class="max-w-[280px]">
                        <div class="text-gray-700 font-medium">
                          {{ $t(PREFERENCES_DATA.SHARED_ITEMS.title) }}
                        </div>
                        <div class="text-gray-500 font-normal">
                          {{ $t(PREFERENCES_DATA.SHARED_ITEMS.description) }}
                        </div>
                      </div>
                    </template>
                    <ToggleElement name="email" class="mb-3">
                      {{ $t("Email") }}
                    </ToggleElement>
                    <ToggleElement name="browser" class="mb-3">
                      {{ $t("Browser") }}
                    </ToggleElement>
                    <ToggleElement name="mobile_app" class="mb-3">
                      {{ $t("Mobile App") }}
                    </ToggleElement>
                  </ObjectElement>
                </div>
              </ObjectElement>
            </div>
          </div>

          <div class="sticky bg-white bottom-0">
            <hr class="my-5">
            <div class="flex justify-end gap-3 pb-4">
              <HawkButton type="outlined" @click="router.push({ name: 'home' })">
                {{ $t("Cancel") }}
              </HawkButton>
              <ButtonElement
                button-class="w-full bg-blue-600"
                name="submit"
                :submits="true"
              >
                {{ $t("Save") }}
              </ButtonElement>
            </div>
          </div>
        </div>
      </Vueform>
    </div>
  </div>
</template>
