<script setup>
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';

import { cloneDeep } from 'lodash-es';
import tippy from 'tippy.js';
import { useRoute, useRouter } from 'vue-router';
import { useFormDetailStore } from '~/forms/store/form-detail.store.js';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useFormsStore } from '~/forms/store/forms.store';
import FamNormalFlowFilters from '~/forms-as-module/components/fam-normal-flow-filters.vue';
import { useFamCustomView } from '~/forms-as-module/composables/fam-custom-view.composable.js';

const form_detail_store = useFormDetailStore();
const forms_store = useFormsStore();

const { setActiveViewID, getAdvanceDataFilters, display_filters, handleDisplayFilterConfiguration } = useFamCustomView({ feature: 'calendar_view' });
setActiveViewID();
const route = useRoute();
const router = useRouter();

const $services = inject('$services');
const $t = inject('$t');

const calendar_container = ref(null);
const form$ = ref(null);
const form_data = ref(null);

const $additional_filter = ref(null);

const state = reactive({
  active_view: 'month',
  timeline_loader: false,
  active_date: dayjs().startOf('day').toDate(),
  cached_months: [],
  active_date_range: [],
  attached_events: [],
  filters: {},
  group_by_mode: { name: 'priority', label: $t('Priority') },
  timeline_x_start: 0,
  timeline_length: 14,
  open_folders: [],
  is_getting_tasks: false,
  forms: [],
  form_status_map: {},
  normal_flow_filters: null,

});
const form_template_detail_store = useFormTemplateDetailStore();

const formatted_templates = computed(() => {
  return state.forms.map((form) => {
    const start_date = dayjs(form.start_date);
    const due_date = dayjs(form.due_date);
    return {
      ...form,
      id: form.uid,
      text: DOMPurify.sanitize(form.name, { ALLOWED_TAGS: [] }) || 'Invalid form name',
      start_date: dayjs(start_date).isValid() ? dayjs(start_date).startOf('day').toDate() : dayjs(due_date).startOf('day').toDate(),
      end_date: dayjs(due_date).endOf('day').toDate(),
      category: form.category ? form.category : 'not-set',
      assignees: form.members?.length ? form.members : ['not-set'],
      tags: form.tags?.length ? form.tags : ['not-set'],
      color: form?.status?.color ? `${form.status.color}1a` : '#6670851a',
    };
  });
});
const view_map = {
  day: 0,
  week: 1,
  month: 2,
  timeline: 3,
};

const timeline_options = computed(() => [
  ['day', $t('Day'), 'day'],
  ['week', $t('Week'), 'week'],
  ['month', $t('Month'), 'month'],
  // ['timeline', $t('Timeline'), 'timeline'],
].map((option, index) => ({
  uid: index,
  label: option[1],
  action: option[2],
})));

const current_date_text = computed(() => {
  if (state.active_view === 'day')
    return `${dayjs(state.active_date).format('DD MMM YYYY')}`;
  else if (state.active_view === 'week')
    return `${dayjs(state.active_date).startOf('week').format('DD MMM YYYY')} to ${dayjs(state.active_date).endOf('week').format('DD MMM YYYY')}`;
  else if (state.active_view === 'month')
    return `${dayjs(state.active_date).format('MMM YYYY')}`;
  else
    return `${dayjs(state.active_date_range[0]).format('DD MMM YYYY')} to ${dayjs(state.active_date_range[1]).format('DD MMM YYYY')}`;
});

async function getData({ start_date, end_date }, append = true) {
  try {
    const { data } = await $services.forms.post({
      attribute: 'calendar-view',
      body: {
        filters: {
          ongoing: true,
          template: route.params.template_uid,
          // parent_form_uid: form_template_detail_store?.form_template_detail?.uid,
          start_date: start_date || dayjs(state.active_date_range[0]).startOf('day').toISOString(),
          due_date: end_date || dayjs(state.active_date_range[1]).endOf('day'),
          ...(route.params.asset_id && { asset_uid: route.params.asset_id }),
          ...(form_template_detail_store.is_template_flow
            ? { advanced_filters: getAdvanceDataFilters($additional_filter) }
            : state.normal_flow_filters),
        },
      },
      signal: $additional_filter.value?.signal,
    });
    if (!append)
      state.forms = [];

    state.forms = [...state.forms, ...data.forms];
    state.form_status_map = state.forms.map(f => f.status).reduce((acc, cur) => {
      if (cur?.submission_status)
        acc[cur?.submission_status] = cur?.color || '#D92D20';
      return acc;
    }, {});
  }
  catch (e) {
    logger.error(e);
  }
}

forms_store.$onAction(async ({ name, after }) => {
  after(async () => {
    if (['create_form', 'delete_form', 'archiveForms'].includes(name)) {
      await getData({}, false);
      refreshView();
    }
  });
});

form_detail_store.$onAction(async ({ args, name, after }) => {
  after(async () => {
    if (name === 'update_form_details') {
      const form_updates = args[0].body;
      const update_fields = ['name', 'status', 'start_date', 'due_date'];
      const updatedForms = state.forms.map((form) => {
        if (form.uid === form_updates.uid) {
          update_fields.forEach((field) => {
            if (field in form_updates)
              form[field] = form_updates[field];
          });
          return form;
        }
        else { return form; }
      });

      state.forms = Object.assign(updatedForms);
      refreshView();
    //  find out the form in state and update the keys
    }
    else if (['approve_form', 'reopen_form', 'save_form', 'submit_form'].includes(name)) {
      await getData({}, false);
      refreshView();
    }

    // reinitialize tippy for changes
    setTimeout(() => {
      tippy('[data-tippy-content]');
    }, 100);
  });
});

async function applyFilters(data) {
  if (!form_template_detail_store.is_template_flow)
    state.normal_flow_filters = data;

  await getData({}, false);
  refreshView();
}

function configureScheduler() {
  const scheduler = window.scheduler;
  scheduler.config.header = [
    'day',
    'week',
    'month',
    'date',
    'prev',
    'today',
    'next',
  ];
  scheduler.plugins({
    tooltip: true,
    timeline: true,
    all_timed: true,
    multisection: true,
    treetimeline: true,
    container_autoresize: true,
  });

  // allows preventing short events from overlapping
  scheduler.config.separate_short_events = false;
  // By default, events scheduled to the same time are displayed one by one. To display them as a cascade, make this true.
  scheduler.config.cascade_event_display = false;
  // sets the maximum number of events in a cascade
  scheduler.config.cascade_event_count = 0;
  // sets the left margin for a cascade of events
  scheduler.config.cascade_event_margin = 0;
  // only events < 24 hours will be displayed as usual ones
  scheduler.config.all_timed = false;
  // rest of multiday events would be displayed at the top
  scheduler.config.multi_day = true;
  // enables the possibility to render the same events in several sections of the Timeline or Units view
  scheduler.config.multisection = true;
  // enables the possibility to create events by double click
  scheduler.config.dblclick_create = false;
  // enables the possibility to resize multi-day events in the Month view by drag-and-drop
  scheduler.config.resize_month_events = true;
  // 'says' to use the extended form while creating new events by drag or double click
  scheduler.config.details_on_create = false;
  // 'says' to open the lightbox after double clicking on an event
  scheduler.config.details_on_dblclick = false;
  // enables/disables the marker displaying the current time
  scheduler.config.mark_now = true;
  // enables/disables caching of GET requests in the browser
  scheduler.config.prevent_cache = false;
  // allows working with recurring events independently of time zones
  scheduler.config.occurrence_timestamp_in_utc = true;
  // defines whether the date specified in the 'End by' field should be exclusive or inclusive
  scheduler.config.include_end_by = true;
  // prevents including past days to events with the 'weekly' recurrence
  scheduler.config.repeat_precise = true;
  // enables the possibility to create new events by drag-and-drop
  scheduler.config.drag_create = true;
  // allows dragging scheduler events by any part of the body
  scheduler.config.drag_event_body = true;
  // enables the possibility to move events by drag-and-drop
  scheduler.config.drag_move = true;
  // resizing the start and due date of tasks
  scheduler.config.drag_resize = true;
  // sets the maximum number of events displayable in a cell
  scheduler.config.max_month_events = 2;
  // enables the possibility to resize single-day events in the Month view by drag-n-drop
  scheduler.config.resize_month_timed = true;
  // enables setting of the event's duration to the full day
  scheduler.config.full_day = true;
  // sets the start day of weeks
  scheduler.config.start_on_monday = false;
  // sets the minimum height of cells in the month view
  scheduler.config.month_day_min_height = 120;
}
function setTemplates() {
  const scheduler = window.scheduler;

  // helper functions
  const formatWeekDay = (date) => {
    return scheduler.date.date_to_str('%l')(date);
  };
  const formatDay = (date) => {
    return scheduler.date.date_to_str('%j')(date);
  };

  scheduler.templates.tooltip_text = function (_start, _end, event) {
    return `${event.text}`;
  };
  scheduler.templates.event_header = function (_start, _end, event) {
    return `<span class="event_line__status-tag capitalize !top-[unset]" style="background-color:${event?.status?.color || '#667085'}">${(event?.status?.name || event?.status?.submission_status)} </span>`;
  };
  scheduler.templates.event_text = function (_start, _end, event) {
    const repeat_icon = event?.rrule ? '<span class="dhx_cal_event_line__repeat-icon"></span>' : '';
    return `
      <div class="flex w-full items-center border-l-4 h-full rounded" style="border-color:${event?.status?.color || '#667085'}">
        ${repeat_icon}
        <div class="whitespace-nowrap truncate text-black pl-[10px]">${event.text}</div>
        <span class="event_line__status-tag capitalize !top-[unset]" style="background-color:${event?.status?.color || '#667085'}">${(event?.status?.name || event?.status?.submission_status)} </span>
      </div>
    `;
  };
  scheduler.templates.event_bar_text = function (_start, _end, event) {
    const repeat_icon = event?.rrule ? '<span class="dhx_cal_event_line__repeat-icon"></span>' : '';
    return `
    <div class="flex w-full items-center border-l-4 hover:border-l-4 hover:border h-[28px] rounded pl-[10px]" style="border-color:${event?.status?.color || '#667085'}">
        ${repeat_icon}
        <span class="whitespace-nowrap truncate">${event.text}</span>
        <span class="event_line__status-tag capitalize !top-[unset]" style="background-color:${event?.status?.color || '#667085'}">${(event?.status?.name || event?.status?.submission_status)} </span>
      </div>
    `;
  };
  scheduler.templates.event_class = function (start, end) {
    const classes = ['event_line', 'rounded', 'group p-0'];
    if (dayjs(end).diff(start, 'day') < 3)
      classes.push('event_line--short');

    return classes.join(' ');
  };
  scheduler.templates.month_scale_date = function (date) {
    return formatWeekDay(date).slice(0, 3);
  };
  scheduler.templates.month_date_class = function (date) {
    if (dayjs(date).isSame(state.active_date, 'day'))
      return 'outline outline-1 outline-primary-600';
  };
  scheduler.templates.hour_scale = function (date) {
    return scheduler.date.date_to_str('%g %A')(date);
  };
  scheduler.templates.day_scale_date = function (date) {
    return `
      <div class="flex flex-col items-center w-min">
        <div class="text-sm text-primary-600">
          ${formatWeekDay(date).slice(0, 3)}
        </div>
        <div class="w-[52px] h-[52px] rounded-full bg-primary-600 flex items-center justify-center font-semibold text-white text-xl">
          ${formatDay(date)}
        </div>
      </div>
    `;
  };
  scheduler.templates.week_scale_date = function (date) {
    const is_current_date = dayjs().isSame(date, 'day');
    const is_active_date = dayjs(state.active_date).isSame(date, 'day');
    let day_classes = 'text-gray-900';
    let week_day_classes = 'text-gray-600';
    if (is_current_date)
      day_classes = 'border border-gray-100 bg-gray-100';
    if (is_active_date) {
      week_day_classes = 'text-primary-600';
      day_classes = 'bg-primary-600 text-white border border-primary';
    }
    return `
      <div class="flex flex-col items-center w-full">
        <div class="text-sm ${week_day_classes}">
          ${formatWeekDay(date).slice(0, 3)}
        </div>
        <div class="w-[52px] h-[52px] rounded-full flex items-center justify-center font-semibold text-xl ${day_classes}">
          ${formatDay(date)}
        </div>
      </div>
    `;
  };
}
function createTimelineView() {
  scheduler.createTimelineView({
    name: 'timeline',
    x_unit: 'day',
    x_date: '%M %j',
    x_step: 1,
    x_size: state.timeline_length,
    x_start: state.timeline_x_start,
    y_unit: sections_data.value[state.group_by_mode.name],
    y_property: state.group_by_mode.name,
    render: 'tree',
    round_position: true,
    columns: [{ template: columnTemplate }],
    dx: 250,
  });
}

async function onResizeOrMove(id) {
  const form = scheduler.getEvent(id);
  await $services.forms.post({
    body: {
      forms: {
        update: [
          {
            uid: id,
            start_date: form.start_date.toISOString(),
            due_date: form.end_date.toISOString(),
          },
        ],
      },
    },
  });

  refreshView();
}
function attachEvents() {
  const scheduler = window.scheduler;

  scheduler.attachEvent('onBeforeViewChange', () => {
    scheduler.xy.bar_height = 32;
    return true;
  });

  scheduler.attachEvent('onTemplatesReady', () => {
    scheduler.templates.event_bar_date = function () {
      return '';
    };
  });

  // Disable lightbox
  scheduler.attachEvent('onBeforeLightbox', () => {
    return false;
  });

  // Open task
  scheduler.attachEvent('onClick', (id) => {
    router.push({ query: { ...route.query, form: btoa(JSON.stringify({ form_uid: id })) } });
  });

  let original_event;
  scheduler.attachEvent('onBeforeDrag', (id, mode) => {
    original_event = cloneDeep(scheduler.getEvent(id));
    if (mode === 'create')
      logger.log('create');

    else
      return original_event?.status.submission_status !== 'submitted' && original_event.can_modify;
  });

  scheduler.attachEvent('onDragEnd', async (id, mode) => {
    if (mode === 'new-size')
      logger.log('new size');

    else
      await onResizeOrMove(id, mode, original_event);
  });

  scheduler.attachEvent('onViewMoreClick', (date) => {
    state.active_view = 'day';
    changeScheduleDate(date);
    return true;
  });

  scheduler.attachEvent('onDataRender', () => {
    tippy('[data-tippy-content]');
    state.timeline_loader = false;
  });
}
function isDateRangeCached(start_date, end_date) {
  let date = start_date.startOf('month');

  while (date.isBefore(end_date.endOf('month'))) {
    if (!state.cached_months.includes(date.format('YYYY-MM')))
      return false;
    date = date.add(1, 'month');
  }

  return true;
}

async function setActiveView(view = 'month') {
  if (view === 'timeline' || state.active_view === 'timeline')
    state.timeline_loader = true;

  state.active_view = view;
  await changeScheduleDate(state.active_date);
}
function refreshView() {
  const scheduler = window.scheduler;
  scheduler.clearAll();
  scheduler.parse(formatted_templates.value);
  if (state.active_view === 'timeline')
    createTimelineView();
}

async function handleUncachedDateRange(start_date, end_date) {
  await getData({
    start_date: start_date.toISOString(),
    end_date: end_date.toISOString(),
  });

  let date = start_date.startOf('month');

  while (date.isBefore(end_date.endOf('month'))) {
    if (!state.cached_months.includes(date.format('YYYY-MM')))
      state.cached_months.push(date.format('YYYY-MM'));
    date = date.add(1, 'month');
  }
  refreshView();
}

function setActiveDateAndActiveDateRange(payload, active_date) {
  const unit = state.active_view === 'timeline' ? 'day' : state.active_view;
  const offset = state.active_view === 'timeline' ? state.timeline_length : 1;

  let final_date;
  if (payload === 'prev' || payload === 'next') {
    const signed_offset = payload === 'prev' ? -offset : offset;
    final_date = active_date.add(signed_offset, unit);
  }
  else {
    final_date = active_date;
  }

  if (state.active_view === 'timeline')
    state.active_date_range = [final_date.startOf('week').toDate(), final_date.add(1, 'week').endOf('week').toDate()];
  else if (state.active_view === 'week')
    state.active_date_range = [final_date.day(0).startOf('day').toDate(), final_date.day(6).endOf('day').toDate()];
  else if (state.active_view === 'month')
    state.active_date_range = [final_date.startOf('month').startOf('week').toDate(), final_date.endOf('month').endOf('week').toDate()];
  else
    state.active_date_range = [final_date.startOf(unit).toDate(), final_date.endOf(unit).toDate()];

  state.active_date = final_date.startOf('day').toDate();
}

async function changeScheduleDate(payload = 'today') {
  const scheduler = window.scheduler;
  let active_date = state.active_date ?? dayjs().startOf('day').toDate();
  if (payload === 'today')
    active_date = dayjs().startOf('day').toDate();
  if (payload === 'prev' || payload === 'next') {
    const unit = state.active_view === 'timeline' ? 'day' : state.active_view;
    active_date = dayjs(active_date).startOf(unit).toDate();
  }
  if (dayjs(payload).isValid())
    active_date = dayjs(payload).startOf('day');
  else
    active_date = dayjs(active_date).startOf('day');

  setActiveDateAndActiveDateRange(payload, active_date);

  let start_date = dayjs(state.active_date).startOf('month').startOf('day');
  let end_date = dayjs(state.active_date).endOf('month').startOf('day');

  if (state.active_view === 'timeline') {
    start_date = dayjs(state.active_date_range[0]).startOf('day');
    end_date = dayjs(state.active_date_range[1]).endOf('day');
  }

  refreshView();

  if (!isDateRangeCached(start_date, end_date))
    await handleUncachedDateRange(start_date, end_date);

  if (state.active_view === 'timeline')
    scheduler.setCurrentView(state.active_date_range[0], state.active_view);
  else
    scheduler.setCurrentView(state.active_date, state.active_view);
}

async function initializeScheduler() {
  try {
    await getData({});
    const { scheduler } = await import('dhtmlx-scheduler');
    window.scheduler = scheduler;
    configureScheduler();
    setTemplates();
    attachEvents();
    scheduler.init(calendar_container.value, new Date(), 'month');
    setActiveView('month');
  }
  catch (error) {
    logger.error(error);
  }
}
function setDate(value) {
  if (!dayjs(state.active_date).isSame(dayjs(value), 'day'))
    state.active_date = value;
  changeScheduleDate(state.active_date);
}

watch(() => state.active_date, (newValue, oldValue) => {
  if (oldValue !== newValue)
    form_data.value.dates = newValue;
});

watch(() => state.active_date_range, (newVal, oldVal) => {
  const start_range_changed = !dayjs(newVal[0]).isSame(oldVal[0]);
  const end_range_changed = !dayjs(newVal[1]).isSame(oldVal[1]);
  if (start_range_changed || end_range_changed) {
    const dates = form_data.value.dates;
    form$.value.clear();
    form_data.value.dates = dates;
    refreshView();
  }
});
onMounted(initializeScheduler);
</script>

<template>
  <div class="p-4">
    <div class="flex justify-between">
      <HawkDisplayFilters
        v-if="form_template_detail_store.is_template_flow"
        ref="$additional_filter"
        :display_filters="display_filters"
        @apply="applyFilters"
      />
      <FamNormalFlowFilters v-else @save="applyFilters" />
      <div class="flex gap-4">
        <hawk-button-group
          :items="timeline_options"
          :active_item="view_map[state.active_view]"
          @day="setActiveView('day')"
          @week="setActiveView('week')"
          @month="setActiveView('month')"
        />
        <HawkMenu
          v-if="form_template_detail_store?.form_template_detail?.can_modify_template"
          :items="[
            { label: $t('Configure filters'), on_click: () => handleDisplayFilterConfiguration() },
          ]"
        >
          <template #trigger>
            <hawk-button icon type="outlined">
              <IconHawkSettingsOne />
            </hawk-button>
          </template>
        </HawkMenu>
      </div>
    </div>
    <div class="forms-calendar-view mt-4 border-t border-solid border-gray-300">
      <div class="scrollbar h-[calc(100vh-254px)] pb-4">
        <div class="flex items-center mx-4 my-5 gap-4">
          <HawkButton type="outlined" @click="changeScheduleDate('today')">
            {{ $t('Today') }}
          </HawkButton>
          <div class="flex gap-3">
            <HawkButton
              icon
              type="text"
              @click="changeScheduleDate('prev')"
            >
              <IconHawkChevronLeft />
            </HawkButton>
            <HawkButton
              icon
              type="text"
              @click="changeScheduleDate('next')"
            >
              <IconHawkChevronRight />
            </HawkButton>
          </div>
          <div v-tippy="current_date_text" class="text-sm whitespace-nowrap truncate cursor-pointer text-gray-900 font-medium">
            {{ current_date_text }}
          </div>
        </div>
        <Vueform ref="form$" v-model="form_data" sync size="sm" class="w-[200px">
          <div class="col-span-12">
            <DateTimeElement
              :default="state.active_date"
              name="dates"
              class="date_time"
              :options="{
                inline: true,
                format: 'dd/MM/yyyy',
                monthChangeOnScroll: false,
              }"
              @change="setDate"
            />
          </div>
        </Vueform>
      </div>
      <div class=" border-l border-solid border-gray-300 ">
        <HawkMenu v-if="state.active_view === 'timeline' && !state.timeline_loader" :items="group_by_modes" class="mt-2 ml-2 absolute top-[5px] left-4 z-999" position="bottom-right" additional_trigger_classes="!ring-0" @select="setGroupByMode">
          <template #item="{ item }">
            <div class="flex flex-col w-full">
              <span class="text-sm text-gray-700 font-medium w-full flex justify-between">
                {{ item.label }}
                <IconHawkCheck v-if="item.name === state.group_by_mode.name" class="text-primary-600" />
              </span>
            </div>
          </template>
          <template #trigger="{ open }">
            <div class="flex cursor-pointer items-center text-sm font-semibold text-gray-600">
              {{ state.group_by_mode.label }}
              <IconHawkChevronUp v-if="open" class="ml-[2px] text-lg" />
              <IconHawkChevronDown v-else class="ml-[2px] text-lg" />
            </div>
          </template>
        </HawkMenu>
        <div v-show="!state.timeline_loader" ref="calendar_container" class="w-full" />
        <hawk-loader v-show="state.timeline_loader" class="z-[1000]" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.date_time {
  :deep(.dp__menu){
    z-index: 1;
    min-width: 0;
    border: none;
  }
}

:deep(.dhx_cal_event:not(.dhx_cal_select_menu) .dhx_body) {
  color: black !important;
  display: flex;
  align-items: center;
  padding: 0px;
}

.forms-calendar-view {
  width: calc(100% + 30px);
  display: grid;
  grid-template-columns: 312px 1fr;
  margin-left: -16px;
}
</style>
