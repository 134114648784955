<script setup>
import { defineAsyncComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import HawkLoader2 from '~/common/components/atoms/hawk-loader-2.vue';

const TaskDetails = defineAsyncComponent({
  loader: () => import('~/tasks/pages/task-details-new/task-details.vue'),
  loadingComponent: HawkLoader2,
});

const TemplateDetails = defineAsyncComponent({
  loader: () => import('~/tasks/pages/task-details-new/template-details.vue'),
  loadingComponent: HawkLoader2,
});

const route = useRoute();
const router = useRouter();
const show_details = ref(false);

watch(
  () => route.query.task || route.query.template,
  async (newId) => {
    if (newId)
      show_details.value = true;
    else if (!newId)
      show_details.value = false;
  },
  { immediate: true },
);
function closeDetails() {
  show_details.value = false;
  const query = { ...route.query };
  delete query.task;
  delete query.template;
  delete query.teleport_to;
  router.replace({ query });
}

const is_template = computed(() => {
  return !!route.query.template;
});
</script>

<template>
  <hawk-modal-container
    v-model="show_details"
    :options="{ teleportTo: route.query.teleport_to ? route.query.teleport_to : 'body' }"
    content_class="w-full h-screen !rounded-none"
    class="!items-start"
    @close="closeDetails()"
  >
    <TemplateDetails v-if="is_template" @close=" closeDetails()" />
    <TaskDetails v-else @close=" closeDetails()" />
  </hawk-modal-container>
</template>
