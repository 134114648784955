<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  warehouse_id: {
    type: String,
    default: '',
  },
});

const { $t, $services, auth_store, route } = useCommonImports();

const inventory_store = useInventoryStore();
const state = reactive({
  active_filter: 'all',
  alert_items: [],
  is_loading: false,
});

async function get_warehouse_items_by_status() {
  try {
    state.is_loading = true;
    const response = await $services.inventory_items.getAll({
      query: {
        warehouse: props.warehouse_id,
        item_status: ['low_stock', 'out_of_stock'],
        is_active: true,
      },
    });
    state.alert_items = response?.data?.items || [];
    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
    logger.error('[DEBUG] inventory-warehouse-alert.vue::51\n', error);
  }
}

const filtered_items = computed(() => {
  if (state.active_filter === 'all')
    return state.alert_items;
  return state.alert_items.filter(item => item.item_status === state.active_filter);
});

const all_filters = computed(() => {
  return [
    {
      label: $t('All'),
      uid: 'all',
      count: state.alert_items?.length,
    },
    {
      label: $t('Out of Stock'),
      uid: 'out_of_stock',
      count: state.alert_items?.filter(item => item.item_status === 'out_of_stock')?.length,
    },
    {
      label: $t('Low Stock'),
      uid: 'low_stock',
      count: state.alert_items?.filter(item => item.item_status === 'low_stock')?.length,
    },
  ];
});
onMounted(() => {
  if (props.warehouse_id
    ? inventory_store.get_active_warehouse_details(props?.warehouse_id)?.permissions?.read
    : auth_store.check_permission('view_warehouses', route.params.asset_id)) {
    get_warehouse_items_by_status();
  }
});
</script>

<template>
  <div class="border rounded-2xl p-5">
    <div class="font-semibold mb-5">
      {{ $t('Alerts') }} <span>({{ state.alert_items?.length }})</span>
    </div>
    <div class="flex flex-wrap gap-3 mb-3">
      <HawkButton
        v-for="item in all_filters"
        :key="item.uid"
        type="outlined" size="xxs"
        :color="state.active_filter === item.uid ? 'active' : 'primary'"
        @click="state.active_filter = item.uid"
      >
        <div>
          <HawkText :content="item.label" />
          ({{ item?.count }})
        </div>
      </HawkButton>
    </div>
    <HawkLoader v-if="state.is_loading" />
    <div v-else-if="!filtered_items?.length" class="text-sm text-gray-500 h-96">
      <div class="pt-6">
        {{ $t('No alerts found') }}
      </div>
    </div>
    <div v-else>
      <div
        v-for="(alert_item, index) in filtered_items" :key="alert_item.uid" class="py-2"
        :class="{ 'border-b': index !== filtered_items?.length - 1 }"
      >
        <div class="flex items-center justify-between pb-2 gap-3">
          <div class="font-medium break-all max-w-[90%]">
            {{ alert_item.name }}
          </div>
          <div class="text-xs flex gap-3 text-red-700">
            <InventoryItemStatus :status="alert_item?.item_status" class="flex-shrink-0" />
            <div v-if="alert_item.lead_time > 0" class="flex gap-1 items-center">
              <IconHawkClockFastForward class="w-4 h-4" />
              <div class="font-medium">
                {{ alert_item.lead_time }} {{ alert_item.lead_time > 1 ? 'days' : 'day' }}
              </div>
            </div>
          </div>
        </div>
        <div class="text-xs flex gap-3">
          <div>{{ $t('Restock Value') }}: {{ alert_item.reorder_point }}</div>
          <div>{{ $t('Current Qty') }}: {{ alert_item.physical_stock_quantity }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
