<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { compressAndUploadImage } from '~/common/composables/compress-and-upload-image.composable.js';
import { isFileExtensionAllowed } from '~/common/utils/common.utils.js';
import { prepareTimeZones } from '~/common/utils/date.util';

const { $services, $t, $toast, auth_store, common_store, router } = useCommonImports();

const form$ = ref(null);
const state = reactive({
  is_loading: false,
  user_details: {},
});

async function getData() {
  try {
    state.is_loading = true;
    if (auth_store?.logged_in_user_details?.user_id) {
      const { data } = await $services.users.get({ id: auth_store.logged_in_user_details.user_id });
      state.user_details = data.user;
    }
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
    logger.log(error);
  }
  finally {
    state.is_loading = false;
  }
}

function onFormMounted(el$) {
  el$.update({
    ...state.user_details,
    phone_number: '',
    timezone: state.user_details?.timezone || null,
  });
}

function softRemovePicture() {
  form$.value.load({ ...form$.value.data, display_picture: null }, true);
}

async function save() {
  try {
    let payload = {
      ...form$.value.data,
      phone_number: form$.value.data?.phone_number?.length ? form$.value.data.phone_number : null,
      uid: auth_store.logged_in_user_details.user_id,
    };

    if (form$.value.data?.display_picture && typeof form$.value.data?.display_picture === 'object') {
      delete form$.value.data?.display_picture?.__file__;

      if (!isFileExtensionAllowed(form$.value.data.display_picture?.name))
        return;

      const cover_image_response = await compressAndUploadImage(
        form$.value.data.display_picture,
        $services.users.generate_presigned_url,
        { full_response: true },
      );

      if (cover_image_response) {
        await $services.users.save_profile_picture({ body: cover_image_response });
        delete payload?.display_picture;
      }
    }
    else {
      payload = {
        ...payload,
        phone_number: form$.value.data?.phone_number?.length ? form$.value.data.phone_number : null,
        display_picture: form$.value.data?.display_picture ? form$.value.data.display_picture : null,
        user_dp: form$.value.data?.display_picture ? form$.value.data.display_picture : null,
      };
    }

    const { data } = await common_store.update_data({
      type: 'patch_update',
      id: auth_store.logged_in_user_details.user_id,
      data: { user: payload },
      service: 'users',
      response_key: 'user',
      append_data: true,
      state_prop: 'internal_users_map',
      update_state: true,
    });

    if (data?.user) {
      $toast({
        text: 'Profile updated successfully',
        type: 'success',
      });
      await getData();
      await auth_store.set_logged_in_user_details({ forceUpdate: true });
    }
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
    logger.log(error);
  }
}

function accountDeletion() {
  window.open('https://forms.gle/vE3CCL7eAfhUZcfT9', '_blank');
}

onMounted(async () => await getData());
</script>

<template>
  <div>
    <div class="mb-5">
      <div class="text-lg font-semibold">
        {{ $t("General") }}
      </div>
      <div class="text-xs text-gray-600">
        {{ $t("General profile settings") }}
      </div>
    </div>
    <HawkLoader v-if="state.is_loading" />
    <div v-else>
      <Vueform
        ref="form$"
        size="sm"
        :display-errors="false"
        :format-load="(data) => data"
        :endpoint="save"
        @mounted="onFormMounted"
      >
        <div class="col-span-12">
          <div class="max-w-[700px] grid gap-4">
            <FileElement
              name="display_picture"
              :label="$t('Profile Photo')"
              accept="image/*"
              :soft-remove="true"
              :drop="true"
              view="image"
              :url="false"
              :auto="true"
              :use_uppy="false"
              :presets="['hawk_file_element']"
              :options="{
                clickable_text: $t('Click to upload'),
                text: $t('or drag and drop'),
                description: $t('SVG, PNG, JPG or GIF'),
              }"
            >
              <template #preview>
                <div
                  v-if="form$?.data?.display_picture && typeof form$?.data?.display_picture === 'string'"
                  class="flex items-center gap-8"
                >
                  <img
                    :src="form$.data.display_picture"
                    alt="Profile"
                    class="object-cover rounded-full w-14 h-14"
                  >
                  <HawkButton
                    type="plain"
                    color="error"
                    @click="softRemovePicture"
                  >
                    {{ $t("Remove") }}
                  </HawkButton>
                </div>
              </template>
            </FileElement>

            <TextElement
              :label="$t('First name')"
              name="first_name"
              :placeholder="$t('Enter first name')"
              rules="required"
            />
            <TextElement
              :label="$t('Last name')"
              name="last_name"
              :placeholder="$t('Enter last name')"
              rules="required"
            />
            <TextElement
              :label="$t('Email')"
              name="email"
              :placeholder="$t('Enter email')"
              rules="required|email" disabled
            />
            <PhoneElement
              allow-incomplete
              unmask
              autocomplete="on"
              :label="$t('Mobile Number')"
              name="phone_number"
              rules="completed"
            />

            <SelectElement
              :label="$t('Timezone')"
              name="timezone"
              :search="true"
              :placeholder="$t('Select your timezone')"
              :default="form$?.data?.timezone"
              :items="prepareTimeZones()"
              :can-deselect="false"
              :can-clear="false"
            />
          </div>
          <hr class="my-5">
          <div class="flex items-center justify-between">
            <HawkButton
              v-if="auth_store.check_split('allow_account_deletion')"
              type="link"
              color="error"
              @click="accountDeletion"
            >
              {{ $t("Delete account") }}
            </HawkButton>
            <div v-else />
            <div class="flex items-center gap-3">
              <HawkButton type="outlined" @click="router.push({ name: 'home' })">
                {{ $t("Cancel") }}
              </HawkButton>
              <ButtonElement
                button-class="w-full bg-blue-600"
                name="submit"
                :submits="true"
                :disabled="state.phone_number?.length"
              >
                {{ $t("Save") }}
              </ButtonElement>
            </div>
          </div>
        </div>
      </Vueform>
    </div>
  </div>
</template>
