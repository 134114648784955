<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { defineAsyncComponent, provide } from 'vue';
import { useRoute } from 'vue-router';

// other
import { useModal } from 'vue-final-modal';

// composables

import { useAuthStore } from '~/auth/stores/auth.store';
import HawkLoader2 from '~/common/components/atoms/hawk-loader-2.vue';

// stores
import { useTasksStore } from '~/tasks/store/tasks.store.js';
// components
import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';

const props = defineProps({
  document: {
    type: Object,
    default: null,
  },
  store_key: {
    type: String,
    default: 'dms',
  },
});
const TasksView = defineAsyncComponent({
  loader: () => import('~/tasks/pages/tasks/tasks-view.vue'),
  loadingComponent: HawkLoader2,
});

const route = useRoute();

const authStore = useAuthStore();
const task_store = useTasksStore(`${props.store_key}_tasks_store`);

const tasks = computed(() => task_store.tasks(false));

const can_create_task = computed(() => authStore.check_permission('create_tasks', route?.params?.asset_id));

const { open: openTaskForm, close: closeTaskForm } = useModal({
  component: TaskForm,
  attrs: {
    task_type: 'task',
    onClose() {
      closeTaskForm();
    },
    async on_submit(data) {
      try {
        const { element, location } = props.document;
        const payload = {
          ...data,
          target_element: element,
          asset: route.params.asset_id,
          ...(location ? { location } : {}),
        };
        await task_store.create_tasks({ tasks: [payload] });
      }
      catch (err) {
        logger.error(err);
      }
      finally {
        closeTaskForm();
      }
    },
  },
});
provide('task_store', task_store);
</script>

<template>
  <div v-show="tasks?.length > 0">
    <hr class="my-4">
    <TasksView
      :store_key="`${store_key}_tasks_store`"
      :is_compact_view="true"
      :is_widget="true"
      :options="{
        query: {
          is_template: false,
          element: document.element,
          ...(route.params.asset_id && { asset_uid: route.params.asset_id }),
        },
      }"
    >
      <template #top-panel>
        <div class="flex items-center justify-between">
          <p class="text-gray-500 font-medium text-sm">
            {{ $t('Tasks') }} {{ tasks.length || 0 }}
          </p>
          <HawkButton v-if="can_create_task" type="link" class="font-semibold text-sm" @click="openTaskForm">
            <IconHawkPlus />{{ $t('New task') }}
          </HawkButton>
        </div>
      </template>
    </TasksView>
  </div>
</template>
