<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  status: {
    default: '',
    type: String,
  },
});
const { $t } = useCommonImports();
const status_value = computed(() => {
  if (props.status === 'in_stock')
    return {};
  else if (props.status === 'low_stock')
    return { name: 'Low stock', color: 'yellow' };
  else if (props.status === 'out_of_stock')
    return { name: 'Out of stock', color: 'red' };
  return {};
});
</script>

<template>
  <HawkBadge v-if="status_value?.name?.length" :color="status_value.color">
    {{ $t(status_value.name) }}
  </HawkBadge>
</template>
