<script setup>
import InventoryItemStatus from '~/inventory/components/inventory-items/inventory-item-status.vue';
import InventoryQuantity from '~/inventory/components/inventory-quantity.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  inventory_items: {
    type: Array,
    default: () => [],
  },
});

const inventory_store = useInventoryStore();
</script>

<template>
  <div
    v-for="(item, index) in inventory_items" :key="item.uid"
    :class="[{ 'border-b': index + 1 !== inventory_items.length },
             { 'hover:rounded-t-lg': index === 0 },
             { 'hover:rounded-b-lg': index + 1 === inventory_items.length },
             { 'bg-gray-50': inventory_store.active_item_uid === item.uid },
    ]"
    class="p-4 hover:bg-gray-50 cursor-pointer min-h-[100px]"
    @click="inventory_store.active_item_uid = item.uid"
  >
    <div class="flex justify-between items-center gap-2">
      <div class="text-xs text-gray-500">
        #{{ item?.number }}
      </div>
      <div class="flex items-center gap-2">
        <HawkBadge v-if="!item.is_active">
          {{ !item.is_active && $t('Inactive') }}
        </HawkBadge>
        <InventoryItemStatus v-else :status="item?.item_status" class="flex-shrink-0" />
      </div>
    </div>
    <div class="font-medium break-all text-sm my-1">
      {{ item?.name }}
    </div>
    <div class="flex justify-between">
      <div class="text-sm">
        <HawkTag v-if="item?.category" color="white">
          <HawkText :content="inventory_store?.get_item_type(item?.category)?.name" />
        </HawkTag>
        <div v-else>
          -
        </div>
      </div>
      <div class="text-sm flex">
        <InventoryQuantity
          :quantity="item.physical_stock_quantity || 0"
          :uom="item?.uom"
        />
      </div>
    </div>
  </div>
</template>
