import { defineAsyncComponent } from 'vue';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';

const Dashboard = defineAsyncComponent({
  loader: () => import('~/dashboard/pages/dashboard.vue'),
  loadingComponent: HawkLoader,
});

const routes = [
  {
    path: '/:asset_id?/dashboard',
    name: 'asset-dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
    },
  },
];
export default routes;
