<script setup>
import { isEmpty } from 'lodash-es';
import sanitize from 'sanitize-s3-objectkey';
import { useRoute } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';

// other
import useEmitter from '~/common/composables/useEmitter';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';

import { useDocumentGlobalActions } from '~/dms/composables/document-global.composable';
// composables
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
// stores
import { useDocumentStore } from '~/dms/store/document.store.js';

// components
import DocumentSidebar from '~/dms/components/documents/document-sidebar.vue';
import DocumentTable from '~/dms/components/documents/table/document-table.vue';

const route = useRoute();
const router = useRouter();
const emitter = useEmitter();

const $t = inject('$t');
const $toast = inject('$toast');
const display_filters_ref = inject('display_filters_ref');

const document_global_actions = useDocumentGlobalActions();
const document_crud = useDocumentCrud();

const document_store = useDocumentStore();
const common_store = useCommonStore();
const authStore = useAuthStore();
const dms_settings_store = useDMSSettingsStore();

const state = reactive({
  is_table_loading: false,
  is_initial_loading: false,
});

// url support
const folder_from_url = computed(() => {
  const folder = route.query?.folder || route.query?.parent || ''; // parent query param is just for backward compatibility, it always has a folder query
  const asset = common_store.assets_map[folder] || null;

  if (route.query?.document && route.query?.folder === undefined) // if only document id is present use case while redirecting from PM
    return { type: 'folder', uid: '' };

  if (folder === 'null')
    return { type: 'organization', uid: 'null' };

  if (asset)
    return { type: 'asset', uid: folder };

  return folder ? { type: 'folder', uid: folder } : null;
});

const document_from_url = computed(() => {
  const document = route.query.document || null;
  return document ? { uid: document, type: document_store.files_map[document]?.type } : null;
});

const is_internal = computed(() => document_store.is_internal);
const search_query = computed(() => document_store.search_query);
const active_folder_meta = computed(() => document_store.active_folder_meta);
const folder_tree = computed(() => document_store.folder_tree(route.params.asset_id));

const document_list = computed(() => {
  return document_store.documents.filter(item => item.archive === false);
});
const empty_state = computed(() => !document_list.value.length && !folder_tree.value.length);
const create_documents = computed(() => authStore.check_permission('create_documents', route?.params?.asset_id));

async function updateFolderTree() {
  await document_store.set_hierarchy(route.params.asset_id);
}

async function readDirectory(directory) {
  const dirReader = directory.createReader();
  const getEntries = new Promise(resolve => dirReader.readEntries(results => resolve(results)));
  const entries = await getEntries;
  return entries;
}

async function getFilesFromDirectories(handles, files = []) {
  handles = handles.sort((a, b) => {
    if (a.isFile === b.isFile)
      return 0;
    return a.isFile ? -1 : 1;
  });
  for (const handle of handles) {
    if (handle.isDirectory) {
      const sub_handles = await readDirectory(handle);
      const sub_files = await getFilesFromDirectories(sub_handles);
      files = [...files, ...sub_files];
    }
    else if (handle.isFile) {
      const file_promise = new Promise(resolve => handle.file(file => resolve(file)));
      const file = await file_promise;
      const webkitRelativePath = handle.fullPath.substring(1);
      file.meta = { ...(file.meta || {}), raw_webkitRelativePath: webkitRelativePath, webkitRelativePath: webkitRelativePath.includes('/') ? sanitize(webkitRelativePath) : undefined };
      files.push(file);
    }
    else { files.push(handle); }
  }
  return files;
}

async function getFilesAndDirectoriesFromEvent(e) {
  const supportsWebkitGetAsEntry = 'webkitGetAsEntry' in DataTransferItem.prototype;
  const fileHandlesPromises = [...e.dataTransfer.items]
    .filter(item => item.kind === 'file')
    .map(item =>
      supportsWebkitGetAsEntry
        ? item.webkitGetAsEntry()
        : item.getAsFile(),
    );

  const handles = [];

  for await (const handle of fileHandlesPromises)
    handles.push(handle);
  const all_files = await getFilesFromDirectories(handles);
  return all_files;
}

async function onFileDrop(e) {
  e.preventDefault();
  const files = await getFilesAndDirectoriesFromEvent(e) || [];

  if (files?.length === 0)
    return;
  document_crud.uploadHandler({
    flag: 'dnd',
    files,
    options: document_crud.getCommonPayload(),
  });
}

watch(() => search_query.value, async (search) => {
  let query = {};
  if (search) {
    const { type } = active_folder_meta.value;
    query = {
      q: search,
      children: false,
      descendants: !['asset', 'organization'].includes(type),
      is_root: false,
    };
  }
  await getData({ query });
  emitter.emit('set-default-expanded');
});

watch(is_internal, async () => {
  state.is_initial_loading = true;
  document_store.set_show_details(false);
  await updateFolderTree();
  setActiveFolder();
  await getData();
  state.is_initial_loading = false;
});

function setActiveFolder() {
  if (route.params.asset_id) {
    document_store.set_active_folder_meta({
      type: 'asset',
      uid: route.params.asset_id,
    });
  }

  else {
    document_store.set_active_folder_meta({
      type: 'organization',
      uid: folder_tree.value?.[0]?.uid || 'null',
    });
  }
}

async function getData(options = {}) {
  const { filters_payload, signal } = unref(display_filters_ref.value || {});
  if (!isEmpty(filters_payload))
    options.filters = { filters: filters_payload };
  state.is_table_loading = true;
  options.signal = signal;
  await document_global_actions.getDocuments(options);
  state.is_table_loading = false;
}

async function onSidebarChange(item) {
  document_store.set_active_folder_meta(item);
  await getData();
  emitter.emit('set-default-expanded');
}

function validateUrlResources() {
  if (
    (folder_from_url.value?.type === 'folder' && !document_store?.folders_hierarchy_map[folder_from_url.value?.uid])
    || (folder_from_url.value?.type === 'asset' && !common_store.assets_map[folder_from_url.value?.uid])
    || (document_from_url.value && document_store?.active_item_details === undefined)
  ) {
    $toast({
      title: $t('Resource not found'),
      text: $t('The folder and/or file provided in the URL do not exist'),
      timeout: 5000,
      type: 'error',
      position: 'bottom-right',
    });
    return false;
  }
  else {
    return true;
  }
}

async function handleUrlResources() {
  await handleUrlFolder();
  if (document_from_url.value)
    handleUrlDocument();

  return !validateUrlResources();
}

async function handleUrlFolder() {
  document_store.set_active_folder_meta(folder_from_url.value);
  await getData();
}

function handleUrlDocument() {
  document_store.set_active_item_meta(document_from_url.value);
  document_store.set_show_details(true);
}

onBeforeMount(async () => {
  state.is_initial_loading = true;
  await updateFolderTree();
  if (route.query?.document && !route.query?.folder && route.query?.pm) {
    let parent = 'null';
    const { details } = await document_store.fetch_document(route.query.document);
    if (details?.[0]?.parent)
      parent = details[0].parent;
    else
      parent = details?.[0]?.asset || 'null';

    router.replace({
      query: {
        document: route.query.document,
        folder: parent,
      },
    });
    folder_from_url.value.type = details?.[0]?.parent ? 'folder' : 'asset';
    folder_from_url.value.uid = parent;
    document_store.set_active_folder_meta({ ...folder_from_url.value });
  }
  if (folder_from_url.value) {
    const resource_not_found = await handleUrlResources();
    if (resource_not_found) {
      setActiveFolder();
      await getData();
    }
  }
  else {
    setActiveFolder();
    await getData();
  }

  state.is_initial_loading = false;
});

onMounted(async () => {
  await dms_settings_store.fetch_all_statuses();
  if (!dms_settings_store.configuration?.uid)
    await dms_settings_store.set_configuration();
});

onBeforeUnmount(() => {
  const previous_state = document_store.is_internal;
  document_store.$reset();
  document_store.is_internal = previous_state;
});

defineExpose({
  getData,
});
</script>

<template>
  <div v-if="state.is_initial_loading">
    <hawk-loader />
  </div>
  <div v-else-if="empty_state">
    <HawkIllustrations :has_permission="create_documents" :is_create_indicator="create_documents" type="on-boarding" for="files" />
  </div>
  <div v-else class="container flex 2xl:max-w-[100vw] gap-4">
    <div v-if="!search_query && !display_filters_ref?.filters?.length" class="w-[314px] flex-auto">
      <HawkIllustrations v-if="!folder_tree?.length" type="no-data" for="folders" variant="mini_vertical" />
      <DocumentSidebar
        v-else
        :active_folder="active_folder_meta"
        :data="folder_tree"
        @active-folder-change="onSidebarChange"
      />
    </div>
    <div id="table_wrapper" class="flex-auto w-[calc(100vw-378px)]">
      <hawk-dnd-area :on_file_drop="onFileDrop">
        <DocumentTable
          :documents="document_list"
          :refresh_documents="getData"
          :is_table_loading="state.is_table_loading"
          :options="{
            dropdown_items_ids: ['rename', 'edit', 'share', 'download', 'transfer', 'autonumbering', 'task', 'form', 'weightage', 'versions', 'move', 'integrate', 'archive', 'delete', 'export'],
          }"
        />
      </hawk-dnd-area>
    </div>
  </div>
</template>
