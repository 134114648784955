<script setup>
import { defineAsyncComponent, onBeforeMount } from 'vue';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const Dashboard = defineAsyncComponent({
  loader: () => import('~/dashboard/pages/dashboard.vue'),
  loadingComponent: HawkLoader,
});
const { route, router, auth_store } = useCommonImports();

onBeforeMount(() => {
  if (!auth_store.check_permission('view_inventory_reports', route.params.asset_id)) {
    router.push({
      name: 'inventory-permission-denied',
    });
  }
});
</script>

<template>
  <div class="w-[calc(100vw_-_80px)]">
    <Dashboard
      :options="{
        can_modify_resource: auth_store.check_permission('modify_inventory_reports', route.params.asset_id),
        hide_side_bar: true,
        is_stand_alone: false,
        hide_dashboard_options: false,
        show_top_bar: false,
        dashboard_label: 'Reports',
      }"
    />
  </div>
</template>
