<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import NewTransmittalForm from '~/dms/components/transmittals/forms/new-transmittal-form.vue';
import TransmittalsList from '~/dms/components/transmittals/list/transmittals-list.vue';
import { useTransmittalPermissions } from '~/dms/composables/transmittal-permissions.composable';
import { TRANSMITTAL_STATUS } from '~/dms/constants';

import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';

const active_tab = inject('active_transmittal_tab');

const transmittals_store = useTransmittalsStore();
const transmittal_permissions = useTransmittalPermissions();
const router = useRouter();
const route = useRoute();

const state = reactive({
  is_loading: false,
  search: '',
});

const $additional_filter = ref(null);

const transmittal_request_modal = useModal({
  component: NewTransmittalForm,
  attrs: {
    onClose() {
      transmittal_request_modal.close();
    },
  },
});

const dms_settings_store = useDMSSettingsStore();
dms_settings_store.fetch_all_issue_purposes();

watch(() => [active_tab.value, state.search], async () => {
  getData();
}, { immediate: true });

const transmittals = computed(() => {
  if (active_tab.value === 'outgoing')
    return transmittals_store.transmittals?.filter(outgoing => outgoing.published);

  return transmittals_store.transmittals;
});

function filterPayload(obj, value) {
  if (value.operator === 'between') {
    obj[`${value.field}_start`] = value.value[0];
    obj[`${value.field}_end`] = value.value[1];
  }
  else { obj[value.field] = value.value; }
  return obj;
}

async function getData(e) {
  try {
    const filters = ($additional_filter.value?.filters || []).reduce(filterPayload, {});
    state.loading = !e;
    const query = {
      page_number: e?.pagination_state?.pageIndex || 1,
      page_size: 25,
      ...filters,
      ...(state.search ? { q: state.search } : {}),
      ...(active_tab.value === 'completed' ? { status: 'completed' } : { [active_tab.value]: true }),
    };
    await transmittals_store.set_transmittals({ query, signal: $additional_filter.value?.signal }, !!e);
    state.loading = false;
  }
  catch (err) {
    logger.error(err);
  }
}

const transmittal_status_enum = [
  'pending_upload',
  'waiting_for_upload',
  'pending_review',
  'waiting_for_review',
  'completed',
  'cancelled',
];

const display_filters = computed(() => [
  {
    name: 'Status',
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    options: transmittal_status_enum.map(status => ({ name: TRANSMITTAL_STATUS[status].title, uid: status })),
    uid: 'status',
  },
  {
    name: 'Issue purpose',
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    options: (dms_settings_store?.issue_purposes || []).map(status => ({ name: status.name, uid: status.name })),
    uid: 'issue_purpose',
  },
  {
    name: 'Priority',
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    options: [{ name: 'Critical', uid: 'critical' }, { name: 'High', uid: 'high' }, { name: 'Medium', uid: 'medium' }, { name: 'Low', uid: 'low' }, { name: 'Not set', uid: 'not_set' }],
    uid: 'priority',
  },
  {
    name: 'Due date',
    is_static: true,
    data_type: 'date',
    operators: ['between'],
    uid: 'due_date',
  },
]);
</script>

<template>
  <div class="my-5">
    <div class="flex justify-between">
      <div class="flex ">
        <HawkDisplayFilters
          ref="$additional_filter"
          :display_filters="display_filters"
          @apply="getData()"
        />
      </div>
      <div class="flex items-center">
        <hawk-search-input
          v-model="state.search"

          :placeholder="$t('Search')"
          :debounce_time="400" class="my-[1px]"
        />
        <div v-if="transmittal_permissions.checkTransmittalPermission({ permission: 'create_transmittal' })" v-tippy="!route.params.asset_id ? $t('Switch to an asset to create a transmittal') : null" class="flex cursor-pointer items-center rounded  hover:bg-gray-50 ml-2">
          <hawkButton color="primary" :disabled="!route.params.asset_id" @click="router.push({ name: 'files-create-transmittals' })">
            <icon-hawk-plus class="text-white" /> <span>
              {{ $t('Create Transmittal') }}
            </span>
          </hawkButton>
        </div>
      </div>
    </div>
    <div>
      <hawk-loader v-if="state.loading" />
      <div v-else>
        <TransmittalsList
          :transmittals="transmittals"
          @load-more="getData($event)"
        />
      </div>
    </div>
  </div>
</template>
