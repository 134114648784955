<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { Validator } from '@vueform/vueform';
import { debounce } from 'lodash-es';
import { computed, inject } from 'vue';

const props = defineProps({
  options: {
    type: Object,
    default: () => {},
  },
  data: {
    type: Array,
    default: () => [],
  },
  fieldvalues_map: {
    type: Object,
    default: () => {},
  },
  module_name: {
    type: String,
    default: 'asset',
  },
  label_size: {
    type: Number,
    default: 4,
  },
  input_size: {
    type: Number,
    default: 8,
  },
});
const emit = defineEmits(['update']);
const $t = inject('$t');

function checkPlannedActualValue() {
  return class extends Validator {
    get message() { return $t('Actual cannot be greater than planned'); }

    check(value) {
      return +value.actual <= +value.planned;
    }
  };
}

const custom_fields_data = computed(() => props.data);

const updateField = debounce(async (newValue, field) => {
  if (field.type === 'phone')
    emit('update', { value: newValue.number, field });
  else if (field.type === 'members')
    emit('update', { value: newValue.map(item => item.uid ? item.uid : item), field });
  else
    emit('update', { value: newValue, field });
}, 50);

function add_rule(array) {
  return array.filter(item => item[1]).map(item => item[0] + item[1]);
}

function ruleCheck(element) {
  const rules = [];
  if (props.fieldvalues_map?.[element?.uid]?.properties?.required)
    rules.push('required');
  else
    rules.push('nullable');
  if (element?.type === 'number')
    rules.push('regex:^[0-9]*(\.[0-9]{1,2})?$');
  return rules;
}

function typeLabelsCheckboxRadio(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: element?.type === 'radio' ? 'radiogroup' : 'checkboxgroup',
    label: element?.name,
    items: element?.config?.map?.(field => ({ value: field.uid, label: field.name })) || [],
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required'] : [],
    default: props.fieldvalues_map?.[element?.uid]?.value || [],
    onChange: newValue => updateField(newValue, element),
  };
}

function typeNumberMoney(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'currency',
    inputType: element?.type,
    label: element?.name,
    currency_options: {
      hide_dropdown: true,
      placeholder: '',
    },
    currency_code: element?.properties?.currency,
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required'] : [],
    default: props.fieldvalues_map?.[element?.uid]?.value,
    onChange: newValue => updateField(newValue, element),
  };
}

function typeNumberOther(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'text',
    inputType: element?.type,
    label: element?.name,
    rules: ruleCheck(element),
    default: props.fieldvalues_map?.[element?.uid]?.value,
    onChange: newValue => updateField(newValue, element),
    addons: {
      before: props.fieldvalues_map?.[element?.uid]?.properties?.prefix || '',
      after: props.fieldvalues_map?.[element?.uid]?.properties?.suffix || '',
    },
  };
}

function typeEmail(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'text',
    inputType: element?.type,
    label: element?.name,
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required', 'email'] : ['nullable', 'email'],
    default: props.fieldvalues_map?.[element?.uid]?.value,
    onChange: newValue => updateField(newValue, element),
  };
}

function typeDatetime(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'dateTime',
    inputType: element?.type,
    label: element?.name,
    time: true,
    options: {
      enableTimePicker: true,
      flow: ['calendar', 'time'],
    },
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required'] : [],
    default: props.fieldvalues_map?.[element?.uid]?.value,
    onChange: newValue => updateField(newValue, element),
  };
}

function typePhone(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'phonenumber',
    inputType: element?.type,
    label: element?.name,
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required'] : [],
    default: props.fieldvalues_map?.[element?.uid]?.value,
    onValidate: newValue => updateField(newValue, element),
  };
}

function typeDropdownRadio(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'radiogroup',
    items: element?.config?.map?.(field => ({
      value: field.uid,
      label: field.name,
    })) || [],
    label: element?.name,
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required'] : [],
    default: props.fieldvalues_map?.[element?.uid]?.value,
  };
}

function typeDropdownOther(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'select',
    search: false,
    native: false,
    items: element?.config?.map?.(field => ({ value: field.uid, label: field.name })) || [],
    label: element?.name,
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required'] : [],
    default: props.fieldvalues_map?.[element?.uid]?.value,
  };
}

function typeDate(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'dateTime',
    label: element?.name,
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required'] : [],
    default: props.fieldvalues_map?.[element?.uid]?.value,
    options: {
      format: 'yyyy-MM-dd',
    },
  };
}
function typeDateRange(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'dateTime',
    label: element?.name,
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required'] : [],
    default: props.fieldvalues_map?.[element?.uid]?.value?.start && props.fieldvalues_map?.[element?.uid]?.value?.end
      ? [props.fieldvalues_map?.[element?.uid]?.value?.start, props.fieldvalues_map?.[element?.uid]?.value?.end]
      : [],
    options: {
      range: true,
    },
  };
}

function typeFiles(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    'type': 'multifile',
    'label': element?.name,
    'drop': true,
    'use_uppy': true,
    'soft-remove': true,
    'auto': false,
    'rules': add_rule([
      ['', props.fieldvalues_map?.[element?.uid]?.properties?.required ? 'required' : 'nullable'],
      ['max:', element?.properties?.count >= 0 ? element?.properties?.count : null],
    ]),
    'default': props.fieldvalues_map?.[element?.uid]?.value || [],
    'onChange': newValue => updateField(newValue, element),
  };
}

function typePercentage(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'slider',
    label: element?.name,
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required'] : [],
    default: (props.fieldvalues_map?.[element?.uid]?.value) || 0,
    class: 'mt-6 mb-2',
    onChange: newValue => updateField(newValue, element),
  };
}

function typePlannedActual(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'object',
    label: element?.name,
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required', checkPlannedActualValue(element)] : [checkPlannedActualValue(element)],
    schema: {
      actual: {
        type: 'text',
        placeholder: $t('Actual'),
        inputType: 'number',
        default: props.fieldvalues_map?.[element?.uid]?.value?.actual || null,
        columns: { default: 12, sm: 6 },
        rules: ['min:0', 'nullable', 'numeric'],
        onChange: newValue => updateField({ actual: newValue, planned: props.fieldvalues_map?.[element?.uid]?.value?.planned }, element),
        addons: {
          before: props.fieldvalues_map?.[element?.uid]?.properties?.prefix || '',
          after: props.fieldvalues_map?.[element?.uid]?.properties?.suffix || '',
        },
      },
      planned: {
        type: 'text',
        placeholder: $t('Planned'),
        inputType: 'number',
        default: props.fieldvalues_map?.[element?.uid]?.value?.planned || null,
        columns: { default: 12, sm: 6 },
        rules: ['min:0', 'nullable', 'numeric'],
        onChange: newValue => updateField({ actual: props.fieldvalues_map?.[element?.uid]?.value?.actual, planned: newValue }, element),
        addons: {
          before: props.fieldvalues_map?.[element?.uid]?.properties?.prefix || '',
          after: props.fieldvalues_map?.[element?.uid]?.properties?.suffix || '',
        },
      },
    },
  };
}

function typeFormula(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    submit: false,
    type: 'text',
    inputType: element?.type,
    label: element?.name,
    disabled: true,
    placeholder: $t('This is autogenerated field.'),
    onChange: newValue => updateField(newValue, element),
  };
}

function typeMembers(formatted_vueform_data, field_name, element) {
  formatted_vueform_data[field_name] = {
    type: 'members',
    inputType: element?.type,
    label: element?.name,
    rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required'] : [],
    onChange: newValue => updateField(newValue, element),
    default: props.fieldvalues_map?.[element?.uid]?.value || [],
    options: {
      name: formatted_vueform_data[field_name],
      default: props.fieldvalues_map?.[element?.uid]?.value?.length ? props.fieldvalues_map?.[element?.uid]?.value.map((i) => { return { uid: i }; }) : [],
      existing_users: props.fieldvalues_map?.[element?.uid]?.value?.length ? props.fieldvalues_map?.[element?.uid]?.value.map((i) => { return { uid: i }; }) : [],
      has_teams: true,
      rules: props.fieldvalues_map?.[element?.uid]?.properties?.required ? ['required'] : [],
    },
  };
}

function moduleNameAsset() {
  const formatted_vueform_data = {};
  const custom_fields = custom_fields_data?.value;
  for (const element of custom_fields) {
    const { type, uid, name, properties } = element;
    const field_name = `${uid || name}`;

    switch (type) {
      case 'labels':
      case 'checkbox':
        typeLabelsCheckboxRadio(formatted_vueform_data, field_name, element);
        break;

      case 'text':
      case 'url':
        typeNumberOther(formatted_vueform_data, field_name, element);
        break;

      case 'number':
        if (properties.type === 'money')
          typeNumberMoney(formatted_vueform_data, field_name, element);
        else
          typeNumberOther(formatted_vueform_data, field_name, element);
        break;

      case 'money':
        typeNumberMoney(formatted_vueform_data, field_name, element);
        break;

      case 'email':
        typeEmail(formatted_vueform_data, field_name, element);
        break;

      case 'datetime':
        typeDatetime(formatted_vueform_data, field_name, element);
        break;

      case 'phone':
        typePhone(formatted_vueform_data, field_name, element);
        break;

      case 'dropdown':
        if (properties?.type?.toLowerCase() === 'radio')
          typeDropdownRadio(formatted_vueform_data, field_name, element);
        else
          typeDropdownOther(formatted_vueform_data, field_name, element);
        break;

      case 'radio':
        typeDropdownRadio(formatted_vueform_data, field_name, element);
        break;

      case 'date':
        typeDate(formatted_vueform_data, field_name, element);
        break;

      case 'files':
        typeFiles(formatted_vueform_data, field_name, element);
        break;

      case 'percentage':
        typePercentage(formatted_vueform_data, field_name, element);
        break;

      case 'planned_actual':
        typePlannedActual(formatted_vueform_data, field_name, element);
        break;

      case 'date_range':
        typeDateRange(formatted_vueform_data, field_name, element);
        break;

      case 'formula':
        typeFormula(formatted_vueform_data, field_name, element);
        break;

      case 'members':
        typeMembers(formatted_vueform_data, field_name, element);
        break;
    }
  }
  return formatted_vueform_data;
}

const custom_fields_schema = computed(() => {
  return moduleNameAsset();
});
</script>

<template>
  <ObjectElement
    :name="options.name"
    :schema="custom_fields_schema"
  />
</template>
