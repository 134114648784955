<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { orderBy } from 'lodash-es';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  warehouse_id: { type: String, default: '' },
  include_filters: { type: Array, default: () => ([]) },
});

const emit = defineEmits(['apply']);
const $t = inject('$t');
const inventory_store = useInventoryStore();

const item_types = computed(() => orderBy(inventory_store.item_types, ['name'], ['asc']).map((item_type) => {
  return {
    name: item_type.name,
    uid: item_type.uid,
  };
}));

const warehouses = computed(() => inventory_store.warehouses.map((warehouse) => {
  return {
    name: warehouse.name,
    uid: warehouse.uid,
  };
}));

const display_filters = computed(() => {
  return [
    {
      name: $t('Type'),
      is_static: true,
      data_type: 'single_select',
      operators: ['isAnyOf'],
      option_type: 'single_select',
      options: [...item_types.value],
      uid: 'category',
    },
    {
      uid: 'item_status',
      name: $t('Status'),
      data_type: 'single_select',
      is_static: true,
      option_type: null,
      operators: ['isAnyOf'],
      options: [
        {
          name: $t('Out of stock'),
          uid: 'out_of_stock',
        },
        {
          name: $t('Low stock'),
          uid: 'low_stock',
        },
        {
          name: $t('In stock'),
          uid: 'in_stock',
        },
      ],
      is_blank_option: false,
      type: 'dropdown',
      search: false,
    },
    ...(!props?.warehouse_id
      ? [{
          name: $t('Warehouse'),
          is_static: true,
          data_type: 'single_select',
          operators: ['isAnyOf'],
          option_type: 'single_select',
          options: [...warehouses.value],
          uid: 'warehouse',
        }]
      : []),
  ].filter(filter => !props.include_filters?.length || props.include_filters?.includes(filter.uid));
});

const $additional_filters = ref(null);
function applyFilters(filter) {
  const filter_value = {};
  filter.forEach((element) => {
    filter_value[element.field] = element.value;
  });
  emit('apply', { filter: filter_value, signal: $additional_filters.value?.signal });
}
</script>

<template>
  <HawkDisplayFilters
    ref="$additional_filters"
    :display_filters="display_filters"
    @apply="applyFilters"
  />
</template>
