<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { watchDebounced } from '@vueuse/core';

const props = defineProps({

  modelValue: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Search',
  },
  debounce_time: {
    type: Number,
    default: 200,
  },
  full_width: {
    type: Boolean,
    default: false,
  },
  show_loader: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const state = reactive({
  form_model: {
    input: props.modelValue,
  },
});

watch(() => props.modelValue, (val) => {
  state.form_model.input = val;
});

watchDebounced(() => state.form_model.input, (val) => {
  emit('update:modelValue', val ? val?.trim() : val);
}, { debounce: props.debounce_time });
</script>

<template>
  <div class="grid col-span-12">
    <Vueform
      v-model="state.form_model"
      size="sm"
      sync
    >
      <TextElement
        name="input"
        :placeholder="props.placeholder"
        :default="modelValue"
        class="w-full"
        :class="{ 'md:w-[280px]': !full_width }"
        autocomplete="off"
      >
        <template #addon-before>
          <IconHawkSearchMd class="h-5 w-5 text-gray-600" aria-hidden="true" />
        </template>
        <template #addon-after>
          <HawkLoader v-if="show_loader" :height="5" :width="5" container_class="m-0" />
        </template>
      </TextElement>
    </Vueform>
  </div>
</template>
