<script setup>
import { isEmpty } from 'lodash-es';
import { useRoute } from 'vue-router';

// composables
import { useDocumentGlobalActions } from '~/dms/composables/document-global.composable';

// stores
import { useDocumentStore } from '~/dms/store/document.store.js';

// components
import DocumentTable from '~/dms/components/documents/table/document-table.vue';

const route = useRoute();

const document_global_actions = useDocumentGlobalActions();
const document_store = useDocumentStore();

const display_filters_ref = inject('display_filters_ref');

const is_internal = computed(() => document_store.is_internal);
const active_folder_meta = computed(() => document_store.active_folder_meta);
const document_list = computed(() => document_store.documents?.filter(document => document.archive));

const state = reactive({
  is_initial_loading: false,
  is_table_loading: false,
});

async function getData(options = {}) {
  const { filters_payload, signal } = unref(display_filters_ref.value || {});
  if (!isEmpty(filters_payload))
    options.filters = { filters: filters_payload };
  state.is_table_loading = true;
  options.signal = signal;
  await document_global_actions.getDocuments(options);
  state.is_table_loading = false;
}

watch(is_internal, async () => {
  state.is_initial_loading = true;
  setActiveMeta();
  await getData();
  state.is_initial_loading = false;
}, { immediate: true });

watch(() => document_store.search_query, async (search) => {
  let query = {};
  if (search) {
    const { type } = active_folder_meta.value;
    query = {
      q: search,
      children: false,
      descendants: !['asset', 'organization'].includes(type),
    };
  }
  await getData({ query });
});

function setActiveMeta() {
  if (!route.params.asset_id)
    document_store.set_active_folder_meta({ uid: 'null', type: 'organization' });
  else
    document_store.set_active_folder_meta({ uid: route.params.asset_id, type: 'asset' });
}
defineExpose({
  getData,
});
</script>

<template>
  <div v-if="state.is_initial_loading">
    <hawk-loader />
  </div>
  <div v-else class="container flex 2xl:max-w-[100vw]">
    <div id="table_wrapper" class="flex-auto w-full">
      <DocumentTable
        :documents="document_list"
        :refresh_documents="getData"
        :is_table_loading="state.is_table_loading"
        :options="{
          dropdown_items_ids: ['archive', 'delete'],
        }"
      />
    </div>
  </div>
</template>
