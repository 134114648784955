<script setup>
import { useModal } from 'vue-final-modal';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { searchData } from '~/common/utils/common.utils';
import InventoryWarehouseForm from '~/inventory/components/inventory-warehouse/inventory-warehouse-form.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const { $t, router, route, auth_store } = useCommonImports();
const inventory_store = useInventoryStore();

const columns = computed(() => {
  return [
    {
      accessorKey: 'number',
      header: $t('Code'),
      id: 'number',
      font_medium: true,
      size: 150,
    },
    {
      accessorKey: 'name',
      header: $t('Name'),
      id: 'name',
      size: 200,
    },
    {
      accessorKey: 'warehouse_type',
      header: $t('Type'),
      id: 'warehouse_type',
      size: 200,
    },
    {
      accessorKey: 'description',
      header: $t('Description'),
      id: 'description',
      size: 300,
    },
    ...[route?.params?.asset_id
      ? {
          accessorKey: 'item_status_count',
          header: $t('Stock alerts'),
          id: 'item_status_count',
          size: 330,
        }
      : {
          accessorKey: 'assets',
          header: $t('Assets'),
          id: 'assets',
          size: 330,
        }],
  ];
});

const state = reactive({
  search: '',
});

const {
  open: openAddWarehouseModal,
  close: closeAddWarehouseModal,
} = useModal({
  component: InventoryWarehouseForm,
  attrs: {
    onClose() { closeAddWarehouseModal(); },
  },
});

const filtered_warehouses = computed(() => searchData(inventory_store.warehouses, state.search, ['name', 'number']));

function getWarehouseTypeDetails(warehouse_type) {
  return inventory_store.warehouse_types_map[warehouse_type];
}

function getWarehouseDraftCount(warehouse) {
  return warehouse?.transaction_metrics?.find(metric => metric.name === 'draft')?.count;
}

function handleRowClick(e) {
  if (e?.permissions?.read)
    router.push({ name: 'inventory-warehouse-details', params: { ...route.params, warehouse_id: e.uid } });
}

async function getWarehousesData() {
  try {
    state.is_loading = true;
    await inventory_store.set_warehouses();
    state.is_loading = false;
  }
  catch (error) {
    logger.log('🚀 ~ getWarehousesData ~ error:', error);
  }
}

function formatTable(cell) {
  if (!cell?.row?.original?.permissions?.read)
    return { cursor: 'not-allowed' };
  return {};
}

onMounted(async () => getWarehousesData());
</script>

<template>
  <HawkPageHeader>
    <template #title>
      {{ $t('Warehouses') }}
    </template>
  </HawkPageHeader>
  <HawkPageSecondaryHeader class="px-4 pb-4">
    <template #left>
      <HawkSearchInput
        v-model="state.search"
        :placeholder="$t('Search')"
      />
    </template>
    <template v-if="auth_store.check_permission('create_warehouses', route.params.asset_id)" #right>
      <HawkButton @click="openAddWarehouseModal">
        <IconHawkPlus class="text-white" />
        {{ $t('New Warehouse') }}
      </HawkButton>
    </template>
  </HawkPageSecondaryHeader>
  <div class="px-4 pb-4">
    <HawkLoader v-if="state.is_loading" />
    <template v-else-if="!filtered_warehouses.length">
      <HawkIllustrations v-if="state.search?.length" type="no-results" />
      <HawkIllustrations v-else type="no-data" for="inventory-warehouses" />
    </template>
    <div v-else-if="filtered_warehouses.length">
      <TableWrapperVue container_class="m-0">
        <HawkTable
          :data="filtered_warehouses"
          :columns="columns"
          cell_height="40px"
          :show_menu_header="false"
          :default_height="500"
          is_gapless
          disable_resize
          :format-table="formatTable"
          @row-clicked="handleRowClick"
        >
          <template #number="{ data }">
            <div>#{{ data.getValue() }}</div>
          </template>
          <template #description="{ data }">
            <div v-if="data?.getValue()">
              {{ data?.getValue() }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #name="{ data }">
            <div class="flex gap-2">
              <div class="break-all">
                {{ data.getValue() }}
              </div>
              <div
                v-if="getWarehouseDraftCount(data.row.original)" v-tippy="{ content: `${getWarehouseDraftCount(data.row.original)} ${$t('Draft')}`, placement: 'auto' }"
                class="grid h-5 w-5 flex-shrink-0 place-content-center rounded-full bg-warning-500"
              >
                <IconHawkClockFastForward class="h-3 w-3 text-white" />
              </div>
            </div>
          </template>
          <template #warehouse_type="{ data }">
            <HawkBadge
              v-if="data.getValue()"
              :custom_color="getWarehouseTypeDetails(data.getValue())?.color"
              custom_color_background_opacity="0.1" class="flex-shrink-0"
            >
              {{ getWarehouseTypeDetails(data.getValue())?.name }}
            </HawkBadge>
            <div v-else>
              -
            </div>
          </template>
          <template #item_status_count="{ data }">
            <div v-if="data.getValue()?.low_stock?.count || data.getValue()?.out_of_stock?.count" class="flex flex-wrap gap-2">
              <HawkTag v-if="data.getValue()?.low_stock?.count" :rounded="false" type="outlined" color="orange" class="flex-shrink-0">
                <IconHawkPackageMinus />
                <div>
                  {{ $t('Low Stock') }} ({{ data.getValue()?.low_stock?.count }})
                </div>
              </HawkTag>
              <HawkTag v-if="data.getValue()?.out_of_stock?.count" :rounded="false" type="outlined" color="red" class="flex-shrink-0">
                <IconHawkPackageX />
                <div>
                  {{ $t('Out of Stock') }} ({{ data.getValue()?.out_of_stock?.count }})
                </div>
              </HawkTag>
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #managers="{ data }">
            <HawkMembers size="xs" :members="data.getValue()" type="badge" />
          </template>
          <template #assets="{ data }">
            <div v-if="data.getValue()?.length" class="flex flex-wrap">
              <div v-for="(asset, index) in data.getValue()" :key="asset" class="flex items-center">
                <HawkAssetName :uid="asset" />
                <span v-if="index !== (data.getValue().length - 1)" class="mr-2">,</span>
              </div>
            </div>
            <div v-else>
              -
            </div>
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
