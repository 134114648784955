<script setup>
import { defineAsyncComponent, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';

import TransmittalDocumentSidebar from '~/dms/components/transmittals/transmittal-sidebar/transmittal-detail-document-sidebar.vue';

// other
import useEmitter from '~/common/composables/useEmitter';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';
// stores
import { useSheetStore } from '~/plans/store/sheet.store';

const SheetViewer = defineAsyncComponent({
  loader: () => import('~/plans/components/sheet-viewer.vue'),
  loadingComponent: HawkLoader,
});
const $t = inject('$t');

const emitter = useEmitter();
const route = useRoute();
const router = useRouter();

const sheet_store = useSheetStore();
const transmittals_store = useTransmittalsStore();

const state = reactive({
  document_details: null,
  document_viewer: null,
  document_tools: null,
  file_url: null,
  is_loading: true,
  sidebar_key: 1,
});

const element = state.document_details?.element || {
  asset: null,
  stage: 'DMS',
  type: 'file',
  uid: route.params.document_uid,
};

const bread_crumbs = computed(() => (
  [
    { uid: '1', label: 'DMS', icon: 'icon-hawk-chevron-right', disabled: false, truncate_length: 10 },
    {
      uid: '2',
      label: state.document_details?.name || '',
      icon: 'icon-hawk-chevron-right',
      disabled: false,
      truncate_length: 30,
    },
    {
      uid: '3',
      label: $t('Add markups'),
      icon: 'icon-hawk-chevron-right',
      disabled: false,
      truncate_length: 20,
    },
  ]
));

function reset_state() {
  state.document_details = null;
  state.document_viewer = null;
  state.document_tools = null;
  state.file_url = null;
  state.is_loading = true;
}

async function fetch_document_data() {
  await transmittals_store.set_transmittal({ transmittal_uid: route.params.transmittal_uid });

  state.document_details = (transmittals_store.transmittal?.documents || []).find(doc => doc.uid === route.params.document_uid);
  state.file_url = state.document_details.presigned_url;
  state.is_loading = false;
}

function updateDocumentDetails() {
  state.document_details = (transmittals_store.transmittal?.documents || []).find(doc => doc.uid === route.params.document_uid);
}

const download_file_url = url => url ? window.open(url, '_blank') : '';
const back_to_transmittal = () => router.replace({ name: 'files-transmittals-detail', params: { transmittal_uid: route.params.transmittal_uid } });

onMounted(() => {
  fetch_document_data();
});

watch(() => route.params.document_uid, (value) => {
  if (value) {
    reset_state();
    fetch_document_data();
  }
});

function initDocViewer(doc_instance, doc_tools) {
  state.document_viewer = doc_instance;
  state.document_tools = doc_tools;
}

async function compare(version_url) {
  const doc = await state.document_tools.compare_pdf(state.file_url, version_url);
  state.document_tools.load_document(shallowRef(state.document_viewer), doc);
  sheet_store.set_compare_view(true);
}

onMounted(() => {
  emitter.on('document_compare', compare);
});

onUnmounted(() => {
  emitter.off('document_compare');
});
</script>

<template>
  <div>
    <template v-if="!state.is_loading">
      <div class="flex justify-between items-center p-4 border-b border-gray-300">
        <hawk-breadcrumbs
          :items="bread_crumbs"
          :truncate_length="100"
          @crumb-clicked="back_to_transmittal"
        />
        <IconHawkX
          class="cursor-pointer text-base hover:text-gray-600 outline-none"
          tabindex="0"
          @click="back_to_transmittal"
        />
      </div>
      <div class="flex h-[calc(100vh-160px)]">
        <div class="p-4 w-[65%]">
          <HawkLoader v-if="!state.file_url" class="relative top-[30%]" />
          <SheetViewer
            v-else
            :key="state.file_url"
            module="dms"
            :can_annotate="state.document_details?.actions?.can_review"
            :sheet_url="state.file_url"
            scroll_view_class="h-full"
            :setup_options="{ extension: state.document_details?.file_name?.split('.')?.at(-1) }"
            :element_uid="route.params.document_uid"
            :element="element"
            :version="state.document_details.versions?.at(-1)?.uid || state.document_details.uid"
            @on-doc-load="initDocViewer"
          >
            <template #err_content>
              <div class="border rounded-lg w-[600px] shadow-md">
                <div class="flex items-start p-6 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800">
                  <div class="flex gap-3 items-center">
                    <HawkFeaturedIcon theme="light-circle-outline" size="sm" color="primary">
                      <IconHawkInfoCircle />
                    </HawkFeaturedIcon>
                    <div class="flex flex-col">
                      <span>{{ $t('Preview not supported') }}</span>
                      <span class="text-sm font-normal text-gray-600">
                        {{ $t('This file is for only download') }}.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="p-6 text-sm text-gray-600">
                  {{ $t('The file') }} {{ state.document_details.name }} {{ $t('can only be downloaded and cannot be opened on our platform.') }} {{ $t('Please click download only to proceed further') }}
                </div>
                <div class="p-6 border-t border-t-gray-200 flex justify-end">
                  <HawkButton @click="() => download_file_url(state.file_url)">
                    {{ $t('Download now') }}
                  </HawkButton>
                </div>
              </div>
            </template>
          </SheetViewer>
        </div>
        <TransmittalDocumentSidebar
          :key="state.sidebar_key"
          :show_menu="false"
          :show_close="false"
          container_classes="!shadow-none border-l"
          :document_details="state.document_details"
          @refresh="updateDocumentDetails();state.sidebar_key += 1;"
        />
      </div>
    </template>
    <template v-else>
      <HawkLoader />
    </template>
  </div>
</template>
