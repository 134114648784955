<script setup>
import { saveAs } from 'file-saver';
import { flatten, groupBy, pick } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import CustomReportPopup from '~/therm/components/customize-report/custom-report-popup.vue';
import { useThermStore } from '~/therm/store/therm.store.js';

const props = defineProps({
  exportType: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close', 'export']);

const { auth_store, common_store, $t, $services } = useCommonImports();

const custom_report_popup = useModal({
  component: CustomReportPopup,
  attrs: {
    onClose() {
      custom_report_popup.close();
    },
  },
});

const form$ = ref(null);
const therm_store = useThermStore();

const get_popup_details = computed(() => {
  if (props.exportType === 'PDF')
    return { title: $t('Export pdf'), label: $t('Include in PDF') };
  return { title: $t('Export'), label: $t('Export as') };
});
const group_report = computed(() => {
  return therm_store.active_group?.summary_report?.report;
});

function getPDFOptionItems() {
  return [
    { label: $t('Defect details'), value: 'defect_details', disabled: true, checked: true },
    { label: $t('Raw images'), value: 'raw_images' },
    { label: `${$t('Attachments')} (${$t('manually added')})`, value: 'attachments' },
    { label: $t('Notes'), value: 'notes' },
    { label: `${$t('Tasks')} (${$t('manually added')})`, value: 'tasks_unassociated' },
  ];
}

function getPDFOptions(data) {
  return {
    show_defects: data.pdf_options?.includes('defect_details'),
    show_raw_images: data.pdf_options?.includes('raw_images'),
    show_attachments: data.pdf_options?.includes('attachments'),
    show_notes: data.pdf_options?.includes('notes'),
    show_tasks_unassociated: data.pdf_options?.includes('tasks_unassociated'),
  };
}
async function handleSelectAll() {
  await form$.value.load({
    options: {
      ...form$.value.data.options,
      projects: Object.values(therm_store?.active_group.projects || {}).map(project => project.uid),
    },
  });
}

async function handleRadioInput(export_as) {
  await form$.value.load({
    options: {
      ...form$.value.data.options,
      is_xlsx: export_as === 'Excel',
      is_pdf: export_as === 'PDF',
      is_geojson: export_as === 'GEOJSON',
      is_custom_report: export_as === 'Custom report',
    },
  });
}

async function handleExportGeojson() {
  try {
    const form_data = form$.value.data.options;
    async function getFeatures(project) {
      const { data } = await $services.common.getAll({
        url: project.vectors,
        query: { tables: form_data.geojson_include.includes('table'), parse_raw_images: true },
      });
      let features = data.features.filter(f => f.properties.class_id !== 100);

      features = features.map((f) => {
        f.properties.featureTypeId = f.properties.class_id || f.properties.featureTypeId;
        return f;
      });

      therm_store.projects_features_map[project.uid] = groupBy(
        features,
        f => f.properties.featureTypeId,
      );

      return data.features.filter(f =>
        (form_data.geojson_include.includes('table') && f.properties.class_id === 100)
        || (form_data.geojson_include.includes('defects') && f.properties.class_id !== 100));
    }
    const projects = form_data.projects.map(project_uid => therm_store.active_group.projects[project_uid]);
    const array_of_features = await Promise.all(projects.map(project => getFeatures(project)));
    const flattened_features = flatten(array_of_features).map(f => pick(f, ['id', 'geometry', 'properties', 'type']));

    const feature_collection = {
      type: 'FeatureCollection',
      features: flattened_features,
    };
    const blob = new Blob([JSON.stringify(feature_collection)], {
      type: 'text/json',
    });
    const current_organization_name = auth_store.current_organization?.name;
    const current_asset_name = common_store.active_asset.name;
    const filename = `${current_organization_name} - ${current_asset_name} - ${projects.map(project => project.name).join(', ')}.json`;

    saveAs(blob, filename);
  }
  catch (error) {
    logger.log(error);
  }
}

async function onSave() {
  const data = form$.value.data.options;
  let export_as = props.exportType;
  if (!export_as && data.is_xlsx)
    export_as = 'Excel';
  else if (!export_as && data.is_pdf)
    export_as = 'PDF';
  else if (!export_as && data.is_geojson)
    export_as = 'GEOJSON';
  else if (!export_as && data.is_custom_report)
    export_as = 'Custom report';

  if (export_as === 'Custom report') {
    const response = await fetch(group_report.value?.url);
    const response_blob = await response.blob();
    saveAs(response_blob, group_report.value?.file_name);
  }
  else if (export_as === 'GEOJSON') {
    await handleExportGeojson();
  }
  else {
    const pdf_options = getPDFOptions(data);
    emit('export', { export_as, pdf_options });
    emit('close');
  }
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :display-errors="false"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ get_popup_details.title }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <ObjectElement :label="get_popup_details.label" name="options">
            <div class="col-span-12">
              <div class="flex flex-col gap-y-2">
                <RadioElement
                  v-if="!exportType"
                  name="is_xlsx"
                  text="Excel"
                  :default="true"
                  :radio-value="true"
                  :add-classes="{
                    RadioElement: {
                      wrapper: 'gap-y-1',
                    },
                  }"
                  @input="handleRadioInput('Excel')"
                />
                <RadioElement
                  v-if="!exportType"
                  name="is_pdf"
                  text="PDF"
                  :radio-value="true"
                  :add-classes="{
                    RadioElement: {
                      wrapper: 'gap-y-1',
                    },
                  }"
                  @input="handleRadioInput('PDF')"
                />
                <div v-if="(exportType === 'PDF' || form$?.data?.options?.is_pdf)" class="ml-4">
                  <CheckboxgroupElement
                    name="pdf_options"
                    :default="['defect_details']"
                    :add-classes="{
                      CheckboxgroupElement: {
                        wrapper: 'gap-y-1.5',
                      },
                    }"
                    :items="getPDFOptionItems()"
                  />
                </div>
                <RadioElement
                  v-if="!exportType && auth_store.is_internal_user"
                  name="is_geojson"
                  text="GEOJSON"
                  :radio-value="true"
                  :add-classes="{
                    RadioElement: {
                      wrapper: 'gap-y-1',
                    },
                  }"
                  @input="handleRadioInput('GEOJSON')"
                />
                <div v-if="form$?.data?.options?.is_geojson" class="ml-4">
                  <TagsElement
                    name="projects"
                    :default="null"
                    :add-classes="{
                      CheckboxgroupElement: {
                        wrapper: 'gap-y-1.5',
                      },
                    }"
                    :rules="['required']"
                    :placeholder="$t('Select projects')"
                    :items="Object.values(therm_store?.active_group.projects || {}).map(project => ({ label: project.name, value: project.uid }))"
                  >
                    <template #before-list>
                      <div class="px-1 mt-1">
                        <div class="py-2.5 px-2 hover:form-bg-input-hover rounded-sm" @click.stop="handleSelectAll()">
                          {{ $t('Select all') }}
                        </div>
                      </div>
                    </template>
                  </TagsElement>
                  <CheckboxgroupElement
                    name="geojson_include"
                    :add-classes="{
                      CheckboxgroupElement: {
                        wrapper: 'gap-y-1.5',
                      },
                    }"
                    :rules="['required']"
                    class="mt-3"
                    :items="[
                      { label: $t('Defects'), value: 'defects', checked: true },
                      { label: $t('Tables'), value: 'table' },
                    ]"
                  />
                </div>
                <RadioElement
                  v-if="!exportType && group_report?.url"
                  name="is_custom_report"
                  text="Custom report"
                  :radio-value="true"
                  :add-classes="{
                    RadioElement: {
                      wrapper: 'gap-y-1',
                    },
                    ElementLayout: {
                      innerContainer: '!flex !items-center',
                      innerWrapperAfter: '!ml-2',
                    },
                  }"
                  @input="handleRadioInput('Custom report')"
                >
                  <template #after>
                    <IconHawkPencilOne class="text-gray-500 h-4.5 w-4.5 cursor-pointer" @click="custom_report_popup.open()" />
                  </template>
                </RadioElement>
              </div>
            </div>
          </ObjectElement>
        </hawk-modal-content>
        <hawk-modal-footer class="flex items-center justify-between">
          <template v-if="auth_store.is_internal_user && !group_report?.url" #left>
            <div class="flex items-center gap-2 cursor-pointer" @click="custom_report_popup.open()">
              <IconHawkPencilOne class="text-gray-500 h-4.5 w-4.5" />
              <div class="text-sm text-gray-600 font-semibold">
                {{ $t('Customize report') }}
              </div>
            </div>
          </template>
          <template #right>
            <div class="flex justify-end items-center">
              <ButtonElement
                :button-label="$t('Cancel')"
                class="mr-3"
                :secondary="true"
                @click="emit('close')"
              />
              <ButtonElement
                :button-label="$t('Export')"
                :submits="true"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
