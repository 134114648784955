import { defineAsyncComponent } from 'vue';
import HawkLoader2 from '~/common/components/atoms/hawk-loader-2.vue';
import Tasks from '~/tasks/pages/tasks.vue';
import ChecklistDetails from '~/tasks/pages/templates/checklist-details.vue';
import ChecklistTemplateList from '~/tasks/pages/templates/checklist-templates-list.vue';
import TaskTemplateList from '~/tasks/pages/templates/task-templates-list.vue';
import TemplatesList from '~/tasks/pages/templates/templates.vue';

const TasksView = defineAsyncComponent({
  loader: () => import('~/tasks/pages/tasks/tasks-view.vue'),
  loadingComponent: HawkLoader2,
});
const routes = [
  {
    path: '/:asset_id?/tasks',
    component: Tasks,
    meta: {

    },
    children: [
      {
        path: '',
        name: 'tasks',
        component: TasksView,
        meta: {
          module: 'tasks',

          title: 'Tasks',
        },
      },
      {
        path: 'templates',
        component: TemplatesList,
        children: [
          {
            name: 'task-templates',
            path: 'tasks',
            component: TaskTemplateList,
            meta: {
              title: 'Task Templates',
            },
          },
          {
            name: 'checklistTemplates',
            path: 'checklists',
            component: ChecklistTemplateList,
            meta: {
              title: 'Checklist Templates',
            },
          },
          {
            name: 'checklistDetails',
            path: 'checklist/:checklist_id?/details',
            component: ChecklistDetails,
            meta: {
              title: 'Checklist Details',
            },
          },
        ],
        meta: {
          module: 'tasks',

          title: 'Tasks',
        },
      },
    ],
  },

];
export default routes;
